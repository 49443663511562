<template>
    <el-dialog
            :title="form.id ? '修改' : '新增'"
            :visible.sync="dialogVisible"
            width="700px"
            @close="dialogClose"
            @open="dialogOpen"
    >
        <el-form
                v-if="dialogVisible"
                ref="form"
                v-loading="loading"
                :model="form"
                class="assest-save-form"
                label-width="150px"
        >
            <el-form-item
                    label="涉及产品"
                    prop="productId"
                    :rules="[ { required: true, message: '涉及产品不能为空', trigger: 'change' }]"
            >
                <el-select
                        v-model="form.productId"
                        class="mr10"
                        placeholder="请选择涉及产品"
                >
                    <el-option
                            v-for="(item,index) in productOptions"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                    label="资产"
                    prop="assetsIds"
                    :rules="[ { required: true, message: '资产不能为空', trigger: 'change' }]"
            >
                <el-select
                        multiple
                        v-model="form.assetsIds"
                        class="mr10"
                        placeholder="请选择资产"
                >
                    <el-option
                            v-for="(item,index) in assestList"
                            :key="index"
                            :label="item.name"
                            :value="String(item.id)"
                    />
                </el-select>
            </el-form-item>

            <el-form-item
                    label="合作业务说明"
                    prop="description"
                    :rules="[ { required: true, message: '合作业务说明不能为空', trigger: 'change' }]"
            >
                <el-input
                        v-input-filter
                        type="textarea"
                        v-model="form.description"
                        maxlength="100"
                />
            </el-form-item>
            <el-form-item
                    label="签订数据使用协议"
                    prop="dataUserProtocol"
                    :rules="[ { required: true, message: '所在区域不能为空', trigger: 'change' }]"
            >
                <el-checkbox v-model="form.dataUserProtocol" :true-label="1" :false-label="0">是</el-checkbox>
            </el-form-item>
            <el-form-item
                    label="第三方合作评估"
                    prop="complianceAssess"
                    :rules="[ { required: true, message: '所在区域不能为空', trigger: 'change' }]"
            >
                <el-checkbox v-model="form.complianceAssess" :true-label="1" :false-label="0">是</el-checkbox>
            </el-form-item>
            <div class="flex-start">
                <el-form-item
                        label="负责人"
                        prop="collaboratePerson"
                        :rules="[ { required: true, message: '负责人不能为空', trigger: 'change' }]"
                >
                    <el-input
                            v-input-filter
                            v-model="form.collaboratePerson"
                            maxlength="100"
                    />
                </el-form-item>
                <el-form-item label="负责人电话" prop="collaboratePerson"
                              :rules="[ { required: true, message: '负责人电话不能为空', trigger: 'change' }]">
                    <el-input
                            v-input-filter
                            v-model="form.collaboratePersonPhone"
                            maxlength="100"
                    />
                </el-form-item>
            </div>

            <el-form-item
                    label="备注"
                    prop="remarks"
            >
                <el-input type="textarea" v-input-filter v-model="form.remarks"/>
            </el-form-item>
        </el-form>
        <div
                slot="footer"
                class="dialog-footer"
        >
            <el-button @click="dialogVisible = false">
                取 消
            </el-button>
            <el-button
                    type="primary"
                    :disabled="loading"
                    @click="submitForm"
            >
                提 交
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
  import { success, error } from '@core/utils/utils'
  import { QueryAssetsOptions } from '@/api/enterprise/assest'
  import { getAllProduct } from '@/api/enterprise/product'

  // eslint-disable-next-line import/named
  import { saveOrUpdateCollaborate, GetCollaborateById } from '@/api/dataMap/collaborate'

  export default {
    data() {
      return {
        users: [],
        depts: [],
        loading: false,
        dialogVisible: false,
        productOptions: [],
        assestList: [],
        form: {
          id: '',
          productId: '',
          collaboratePersonPhone: '',
          collaboratePerson: '',
          complianceAssess: 1,
          dataUserProtocol: 1,
          description: '',
          thirdPartyId: this.$route.query.id,
          assetsIds: [],
        },
      }
    },
    methods: {
      getAllProduct() {
        getAllProduct()
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              this.productOptions = resData.data
            }
          })
      },
      gueryAssetsOptions() {
        QueryAssetsOptions()
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              this.assestList = resData.data
            }
          })
      },
      getAssetsById() {
        GetCollaborateById({ id: this.form.id })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              this.form = resData.data
              this.form.assetsIds = this.form.assetsIds.split(',')
            }
          })
      },
      dialogOpen() {
        this.getAllProduct()
        this.gueryAssetsOptions()
        if (this.form.id) {
          this.getAssetsById()
        }
      },
      dialogClose() {
        this.form = {
          id: '',
          productId: '',
          collaboratePersonPhone: '',
          collaboratePerson: '',
          complianceAssess: 1,
          dataUserProtocol: 1,
          description: '',
          remarks: '',
          thirdPartyId: this.$route.query.id,
          assetsIds: [],
        }
        this.$refs.form.resetFields()
      },
      // eslint-disable-next-line vue/no-dupe-keys
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.form.validate(valid => {
          // eslint-disable-next-line no-empty
          if (valid) {
            this.loading = true
            const submitData = {
              productId: this.form.productId,
              collaboratePersonPhone: this.form.collaboratePersonPhone,
              collaboratePerson: this.form.collaboratePerson,
              complianceAssess: this.form.complianceAssess,
              dataUserProtocol: this.form.dataUserProtocol,
              description: this.form.description,
              remarks: this.form.remarks,
              assetsIds: this.form.assetsIds.toString(),
              thirdPartyId: Number(this.$route.query.id),
            }
            if (this.form.id) {
              submitData.id = this.form.id
            }
            saveOrUpdateCollaborate(submitData)
              .then(res => {
                this.loading = false
                const resData = res.data
                if (resData.code === 0) {
                  success(resData.msg)
                  this.dialogVisible = false
                  this.$emit('handleFilter')
                } else {
                  error(resData.msg)
                }
              })
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style>
    .assest-save-form {
        padding-right: 10px;
    }

    .assest-save-form .el-icon-question {
        position: absolute;
        top: 14px;
        right: -20px;
    }
</style>
