<template>
    <div>
        <el-card class="box-card">
            <el-row
                    class="filter-wrap"
                    :gutter="10"
            >
                <el-col :span="12">
                    <h4>第三方：{{ thridPartyTitle }}</h4>
                </el-col>
                <el-col
                        :span="12"
                        class="text-right"
                >
                    <el-button
                            size="small"
                            v-show="savePerm"
                            type="primary"
                            icon="el-icon-plus"
                            @click="saveDialogShow(1)"
                    >
                        新增
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                    border
                    v-loading="loading"
                    fit
                    highlight-current-row
                    :data="tableData"
                    stripe
                    style="width: 100%"
            >
                <el-table-column
                        label="涉及产品"
                        prop="productName"
                />
                <el-table-column
                        prop="assetsNames"
                        :show-overflow-tooltip="true"
                        label="涉及资产"
                />
                <el-table-column
                        prop="description"
                        label="合作业务说明"
                />
                <el-table-column
                        prop="dataUserProtocol"
                        label="是否签订数据使用相关协议"
                >
                    <template slot-scope="{row}">
                        {{row.dataUserProtocol ? '是' : '否'}}
                    </template>
                </el-table-column>
                <el-table-column prop="complianceAssess" label="是否已做合规评估" >
                    <template slot-scope="{row}">
                        {{row.complianceAssess ? '是' : '否'}}
                    </template>
                </el-table-column>
                <el-table-column prop="collaboratePerson" label="第三方负责人" />
                <el-table-column prop="collaboratePersonPhone" label="负责人联系方式" />
                <el-table-column prop="remarks" label="备注" />

                <el-table-column
                        label="操作"
                        width="100px"
                >
                    <template slot-scope="{row}">
                        <el-link
                                :underline="false"
                                v-show="updatePerm"
                                class="mr10"
                                type="primary"
                                @click="saveDialogShow(2,row.id)"
                        >
                            <el-tooltip  content="修改" placement="top" effect="light">
                                <i class="el-icon-edit" ></i>
                            </el-tooltip>
                        </el-link>
                        <el-link
                                :underline="false"
                                v-show="delPerm"
                                type="primary"
                                @click="delAssest(row.id)"
                        >
                            <el-tooltip  content="删除" placement="top" effect="light">
                                <i class="el-icon-delete" ></i>
                            </el-tooltip>
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                    v-show="total>0"
                    :total="total"
                    :page.sync="listQuery.page"
                    :limit.sync="listQuery.pageSize"
                    @pagination="getList"
            />
        </el-card>
        <CooperationSave
                ref="cooperationSave"
                @handleFilter="handleFilter"
        />
    </div>
</template>

<script>
  // eslint-disable-next-line import/extensions
  import pagination from '@core/components/pagination/Pagination'
  // eslint-disable-next-line import/extensions
  import CooperationSave from '@/views/enterprise/thirdPParty/CooperationSave'
  import { GetCollaborateListByPage,DeleteCollaborateByIds } from '@/api/dataMap/collaborate'
  import { error, findBtnPerm, success } from '@core/utils/utils'
  import { GetThirdPartyById } from '@/api/dataMap/thridParty'

  export default {
    components: {
      pagination,
      CooperationSave,
    },

    data() {
      return {
        savePerm: findBtnPerm('thridParty-7'),
        updatePerm: findBtnPerm('thridParty-8'),
        delPerm: findBtnPerm('thridParty-9'),
        loading: false,
        total: 0,
        listQuery: {
          page: 1,
          pageSize: 10,
          SearchStr: '',
          thirdPartyId: Number(this.$route.query.id),
        },
        tableData: [],
        thridPartyTitle: '',
      }
    },
    created() {
      this.getThridPartyById()
      this.getList()
    },
    methods: {
      getThridPartyById() {
        GetThirdPartyById({ id: Number(this.$route.query.id) }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.thridPartyTitle = resData.data.name
          }
        })
      },
      delAssest(assestId) {
        this.$confirm('是否确认删除资产吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          const ids = []
          ids.push(assestId)
          DeleteCollaborateByIds({ ids: ids }).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
        })
      },
      saveDialogShow(type,assestId) {
        this.$refs.cooperationSave.dialogVisible = true
        // eslint-disable-next-line eqeqeq
        if (type == 2) {
          this.$refs.cooperationSave.form.id = assestId
        }
      },
      getList() {
        this.loading = true
        GetCollaborateListByPage(this.listQuery).then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        }).catch(() => {
          this.loading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
    },
    mounted() {
      this.navActiveInit(1,'第三方管理','企业管理')
    },
    destroyed() {
      this.navActiveInit(2,'第三方管理','企业管理','/thridParty')
    }
  }
</script>

<style scoped>

</style>
