import { get ,post } from '@/libs/axios'

// 分页获取第三方合作列表
export const GetCollaborateListByPage = (params) => post('/dataMap/collaborate/GetCollaborateListByPage',params)
//
// 用id查询第三方合作
export const GetCollaborateById = params => post('/dataMap/collaborate/GetCollaborateById',params)
// 批量删除第三方
export const DeleteCollaborateByIds = params => post('/dataMap/collaborate/DeleteCollaborateByIds',params)
// 创建或更新第三方合作
export const saveOrUpdateCollaborate = params => {
  const submitUrl = params.id ? '/dataMap/collaborate/UpdateCollaborate' : '/dataMap/collaborate/CreateCollaborate'
  return post(submitUrl, params)
}
